import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => (
    <footer>
        <div className="container-fluid" style={{maxWidth: "1600px"}}>
            <div className="row">
                <div className="col-12 col-md-12 col-lg-4 text-center text-lg-start mb-4 mb-md-2">
                    <p className="text-white mb-0">App Semple ®. Todos los derechos reservados.</p>
                </div>
                <div className="col-12 col-md-4 col-lg-3 text-center mb-4 mb-md-0">
                    <a href="https://semple.app/aviso-privacidad" target="_blank">Aviso de Privacidad</a>
                </div>
                <div className="col-12 col-md-6 col-lg-3 text-center mb-4 mb-md-0">
                    <a href="https://semple.app/terminos-condiciones" target="_blank">Términos y Condiciones</a>
                </div>
                <div className="col-12 col-md-2 col-lg-2 text-center text-md-end">
                    <StaticImage src="../images/icon-fb.png" quality={95} formats={["auto", "webp", "avif"]} alt="Facebook" style={{marginRight: "15px"}}></StaticImage>
                    <StaticImage src="../images/icon-in.png" quality={95} formats={["auto", "webp", "avif"]} alt="Instagram"></StaticImage>
                </div>
            </div>
        </div>
    </footer>
)

export default Footer;